import React from 'react';
import './App.css';
import LandingPage from './Components/LandingPage/LandingPage';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <LandingPage />
      <Helmet>
        <script src={"https://www.foodbooking.com/widget/js/ewm2.js"} defer async />
      </Helmet>
    </div>
  );
}

export default App;
